'use client';

import { PricesStoryblok } from '@/types/types-storyblok';
import { StoryblokReactComponent } from '../componentsMap';
import { MARK_BOLD, render } from 'storyblok-rich-text-react-renderer';
import clsx from 'clsx';
import { Lora } from 'next/font/google';
import { storyblokEditable } from '@storyblok/js';
import { useEffect, useRef, useState } from 'react';
import { makeRichTextRenderOptions } from '@/storyblok/utils/richText';
import { StoryblokPriceCard } from './card';
import { StoryblokPricesCompareTable } from './compare-table';

const lora = Lora({ subsets: ['latin'], weight: '500', style: 'italic' });

export const StoryblokPrices: StoryblokReactComponent<PricesStoryblok> = ({ component, story }) => {
  const [selectedTabIdx, setSelectedTabIdx] = useState(component.tabs?.findIndex(tab => tab.selectedByDefault) ?? 0);

  const [visibleCardIdx, setVisibleCardIdx] = useState(0);

  const selectedTab = component.tabs?.at(selectedTabIdx);
  const compareTable = component.compareTable?.at(0);
  const cardsContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const el = cardsContainer.current;
    if (!el) return;
    if (el.childNodes.length !== 3) return;
    const childToScrollIntoView = el.childNodes.item(1) as HTMLDivElement | undefined;
    if (!childToScrollIntoView) return;

    el.scrollTo({ left: childToScrollIntoView.offsetLeft, behavior: 'smooth' });

    const observer = new IntersectionObserver(entry => {
      const { target, isIntersecting } = entry[0];
      if (isIntersecting) {
        setVisibleCardIdx(Array.from(el.children).indexOf(target as HTMLElement));
      }
    });

    el.childNodes.forEach(child => observer.observe(child as HTMLElement));

    return () => observer.disconnect();
  }, []);

  const scrollItemIntoView = (index: number) => {
    const el = cardsContainer.current;
    console.log(el);
    if (!el) return;
    const childToScrollIntoView = el.childNodes.item(index) as HTMLDivElement | undefined;
    console.log(childToScrollIntoView);
    if (!childToScrollIntoView) return;

    el.scrollTo({ left: childToScrollIntoView.offsetLeft, behavior: 'smooth' });
  };

  if (selectedTab === undefined) return null;

  return (
    <section className="max-w-section xl:mx-auto flex flex-col md:px-20 px-4 py-20 relative" {...storyblokEditable(component)}>
      <div className="flex flex-col z-10 relative">
        <div
          aria-hidden
          className=" md:block w-screen  md:w-[700px] lg:w-[1000px] xl:w-[1200px] h-[500px] rounded-full absolute bottom-0 -left-4 blur-[250px] bg-[rgba(81,_130,_239,_0.20)] z-0"
        />
        {component.title && (
          <h2 className="text-center text-neutral-darker text-5xl mb-20">
            {render(
              component.title,
              makeRichTextRenderOptions(story, {
                markResolvers: {
                  [MARK_BOLD]: child => <span className={clsx(lora.className, 'text-elty-green')}>{child}</span>,
                },
              }),
            )}
          </h2>
        )}

        <nav className="flex flex-row bg-white rounded-xl mx-auto mb-20">
          {component.tabs?.map((tab, i) => (
            <li key={tab._uid} className="list-none">
              <button
                className={clsx(
                  'py-4 min-w-40 text-xl rounded-lg transition-colors relative',
                  selectedTabIdx === i
                    ? 'bg-elty-blue text-white hover:bg-primary-hover shadow rounded'
                    : 'text-neutral-darker bg-[#EDF0F4]',
                  i === 0 && 'rounded-r-none',
                  i === component.tabs!.length - 1 && 'rounded-l-none',
                )}
                onClick={() => setSelectedTabIdx(i)}
              >
                {tab.badgeText && (
                  <div
                    className={clsx(
                      'transition-opacity absolute right-0 top-0 -translate-y-1/2 translate-x-1/2',
                      selectedTabIdx !== i && 'opacity-0',
                    )}
                  >
                    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        id="Star 1"
                        d="M26.0503 1.22033C29.0529 -0.670888 33.0237 0.280736 34.8429 3.32751L36.1141 5.45652C36.9999 6.94017 38.4548 7.99628 40.1399 8.37896L42.5579 8.92809C46.0184 9.71394 48.1532 13.1946 47.2852 16.6354L46.6786 19.0397C46.2559 20.7152 46.5379 22.4907 47.4588 23.9528L48.7803 26.0509C50.6716 29.0535 49.7199 33.0243 46.6732 34.8435L44.5442 36.1147C43.0605 37.0005 42.0044 38.4553 41.6217 40.1404L41.0726 42.5585C40.2867 46.0189 36.8061 48.1538 33.3653 47.2858L30.961 46.6792C29.2855 46.2565 27.51 46.5384 26.0479 47.4594L23.9498 48.7809C20.9472 50.6721 16.9764 49.7205 15.1572 46.6737L13.886 44.5447C13.0002 43.0611 11.5453 42.005 9.86024 41.6223L7.44217 41.0732C3.98173 40.2873 1.84685 36.8066 2.7149 33.3659L3.32147 30.9616C3.74418 29.2861 3.46224 27.5106 2.54129 26.0485L1.21975 23.9503C-0.671466 20.9478 0.280158 16.9769 3.32693 15.1578L5.45594 13.8866C6.93959 13.0007 7.9957 11.5459 8.37838 9.86082L8.92751 7.44274C9.71336 3.98231 13.194 1.84743 16.6348 2.71548L19.0391 3.32205C20.7146 3.74476 22.4901 3.46281 23.9522 2.54187L26.0503 1.22033Z"
                        fill="#0BBCA7"
                      />
                    </svg>
                    <span className="absolute left-0 top-0 h-full w-full flex justify-center items-center text-xs font-bold rotate-12 text-white">
                      {tab.badgeText}
                    </span>
                  </div>
                )}
                {tab.title}
              </button>
            </li>
          ))}
        </nav>

        <div
          ref={cardsContainer}
          className="flex flex-row justify-start overflow-x-auto overflow-y-visible lg:overflow-visible lg:justify-center gap-6 py-6 lg:pt-0 px-4 lg:px-0"
        >
          {selectedTab.cards?.map(card => <StoryblokPriceCard key={card._uid} component={card} story={story} />)}
        </div>

        <div className="lg:hidden flex flex-row gap-4 justify-center relative z-10">
          {selectedTab.cards?.map((card, i) => (
            <button
              key={card._uid}
              className={clsx('flex flex-col size-4 rounded-full', i === visibleCardIdx ? 'bg-neutral-400' : 'bg-neutral-100')}
              onClick={() => scrollItemIntoView(i)}
            />
          ))}
        </div>
      </div>

      {compareTable && <StoryblokPricesCompareTable story={story} component={compareTable} prices={component} />}
    </section>
  );
};
