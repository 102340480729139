'use client';

import { useEffect, useRef } from 'react';
import { StoryblokReactComponent } from './componentsMap';
import { CookiebotCookiesDeclarationStoryblok } from '@/types/types-storyblok';

export const StoryblokCookiebotCookiesDeclaration: StoryblokReactComponent<CookiebotCookiesDeclarationStoryblok> = ({ component }) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const el = ref.current;
    if (!el || el.children.length !== 0) return;

    const script = document.createElement('script');
    script.setAttribute('id', 'CookieDeclaration');
    script.setAttribute('src', 'https://consent.cookiebot.com/005326b4-ee8a-4d36-8e8f-d08ea7f2e95a/cd.js');
    script.setAttribute('async', 'true');
    script.setAttribute('type', 'text/javascript');
    el.appendChild(script);
  }, [component.cookiebotUuid]);

  return (
    <>
      <style>{`
        .CookieDeclaration > *:not(.CookieDeclarationType) {
          display: none;
        }
      `}</style>
      <div ref={ref} />
    </>
  );
};
