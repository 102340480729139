'use client';

import { HomeHeroBannerSearchFormStoryblok } from '@/types/types-storyblok';
import Image from 'next/image';
import medicalServiceIcon from '@/assets/medical-services-icon.svg';
import locationMarkerIcon from '@/assets/location-marker-icon.svg';
import searchIcon from '@/assets/search-icon.svg';
import searchOutlineIcon from '@/assets/search-outline-icon.svg';
import { StoryblokReactComponentProps } from '../componentsMap';
import { FormEvent, useRef, useState } from 'react';
import { useCombobox } from 'downshift';
import clsx from 'clsx';
import { SearchClinicAutocompleteResponse, fetchClinicAutocomplete } from './searchForm/clinicAutocomplete';
import { fetchLocationAutocomplete } from './searchForm/locationAutocomplete';
import { storyblokEditable } from '@storyblok/js';

const INPUT_DEBOUNCE_TIME = 300;

const defaultServiceSearchResults: SearchClinicAutocompleteResponse = [
  { type: 'specialization', label: 'Visita Cardiologica' },
  { type: 'specialization', label: 'Visita Dermatologica' },
  { type: 'specialization', label: 'Visita Ginecologica' },
  { type: 'specialization', label: 'Visita Oculistica' },
  { type: 'specialization', label: 'Visita Urologica' },
  { type: 'specialization', label: 'Ecografia Addome Completo' },
  { type: 'specialization', label: 'Radiografia Torace (RX)' },
  { type: 'specialization', label: 'Esame del sangue' },
];

const defaultLocationsSearchResults = ['Roma', 'Milano', 'Bologna', 'Bergamo', 'Monza', 'Torino', 'Brescia', 'Monticello'];

export function StoryblokHomeHeroBannerSearchForm({ component }: StoryblokReactComponentProps<HomeHeroBannerSearchFormStoryblok>) {
  const debounceTimeout = useRef<NodeJS.Timeout>();
  const [serviceSerachResults, setServiceSearchResults] = useState<SearchClinicAutocompleteResponse>(defaultServiceSearchResults);
  const [locationSerachResults, setLocationSearchResults] = useState<string[]>(defaultLocationsSearchResults);

  const updateServicesAutocomplete = (value: string) => {
    if (debounceTimeout.current) clearTimeout(debounceTimeout.current);

    if (value.trim() === '') setServiceSearchResults(defaultServiceSearchResults);
    else
      debounceTimeout.current = setTimeout(() => {
        fetchClinicAutocomplete(value.trim()).then(values => values.length > 0 && setServiceSearchResults(values));
      }, INPUT_DEBOUNCE_TIME);
  };

  const updateLocationAutocomplete = (value: string) => {
    if (debounceTimeout.current) clearTimeout(debounceTimeout.current);

    if (value.trim() === '') setLocationSearchResults(defaultLocationsSearchResults);
    else
      debounceTimeout.current = setTimeout(() => {
        fetchLocationAutocomplete(value.trim()).then(values => values.length > 0 && setLocationSearchResults(values));
      }, INPUT_DEBOUNCE_TIME);
  };

  const onSubmit = (e: FormEvent) => {
    const data = new FormData(e.target as HTMLFormElement);
    const location = data.get('location');
    const service = data.get('service');
    if (!location && !service) e.preventDefault();
  };

  const {
    getInputProps: getServiceInputProps,
    getMenuProps: getServiceMenuProps,
    getItemProps: getServiceItemProps,
    isOpen: isServiceOpen,
    highlightedIndex: highlightedServiceIndex,
  } = useCombobox<SearchClinicAutocompleteResponse[number]>({
    items: serviceSerachResults,
    itemToString: item => item?.label ?? '',
    onInputValueChange: value => {
      updateServicesAutocomplete(value.inputValue ?? '');
    },
  });

  const {
    getMenuProps: getLocationMenuProps,
    getInputProps: getLocationInputProps,
    getItemProps: getLocationItemProps,
    isOpen: isLocationsOpen,
    highlightedIndex: highlightedLocationIndex,
  } = useCombobox<string>({
    items: locationSerachResults,
    itemToString: item => item ?? '',
    onInputValueChange: value => {
      updateLocationAutocomplete(value.inputValue ?? '');
    },
  });

  return (
    <div
      className={clsx(
        'mx-4 md:mx-14 lg:ml-20 xl:mx-auto xl:max-w-screen-xl xl:w-full xl:flex xl:flex-row relative z-[3]  lg:top-0 ',
        component.mobilePosition !== 'before-image' && '-top-14 md:-top-40',
      )}
      {...storyblokEditable(component)}
    >
      <div className="shadow-1 rounded-xl bg-white p-6 flex flex-col gap-6 relative border border-black/5">
        <div className="flex flex-row gap-4">
          <Image src={searchOutlineIcon} alt="search icon" aria-hidden />
          <h2 className="font-semibold text-xl text-neutral-darker">{component.title}</h2>
        </div>
        <p className="text-neutral-darker">{component.subtitle}</p>
        <form
          onSubmit={onSubmit}
          className="flex flex-col gap-3 md:flex-row md:bg-background-neutral md:rounded-full md:p-1 "
          action="/cerca"
        >
          <div className="flex flex-row md:flex-grow items-center bg-background-neutral rounded-lg md:rounded-l-full relative">
            <Image
              className="ml-5 flex-shrink-0 mr-2 absolute md:relative"
              src={medicalServiceIcon}
              alt="icona servizi medici"
              aria-hidden="true"
            />
            <input
              name="service"
              className="bg-background-neutral flex-grow pl-16 md:pl-3 pr-5 rounded-lg md:rounded-none py-3"
              placeholder={component.servicePlaceholder}
              {...getServiceInputProps()}
            />
            <ul
              className={clsx(
                'absolute top-full left-0 bg-white border border-black/5 rounded-lg z-40 min-w-full max-h-64 overflow-y-auto flex flex-col p-3',
                !isServiceOpen && 'hidden',
              )}
              {...getServiceMenuProps()}
            >
              {serviceSerachResults.map((item, index) => (
                <li
                  key={index}
                  className={clsx(
                    'py-3 px-5 flex flex-row gap-1 items-center hover:bg-primary-light-pressed rounded-lg group',
                    index === highlightedServiceIndex && 'bg-primary-light-pressed',
                  )}
                  {...getServiceItemProps({ item, index })}
                >
                  {item.type === 'ambulatory' ? (
                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className={clsx(
                        'flex-shrink-0',
                        highlightedServiceIndex === index ? 'fill-primary-pressed' : 'fill-primary group-hover:fill-primary-pressed',
                      )}
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M1.125 1C1.125 0.654822 1.40482 0.375 1.75 0.375H14.25C14.5952 0.375 14.875 0.654822 14.875 1C14.875 1.34518 14.5952 1.625 14.25 1.625V15.375H14.875C15.2202 15.375 15.5 15.6548 15.5 16C15.5 16.3452 15.2202 16.625 14.875 16.625H1.125C0.779822 16.625 0.5 16.3452 0.5 16C0.5 15.6548 0.779822 15.375 1.125 15.375H1.75V1.625C1.40482 1.625 1.125 1.34518 1.125 1ZM3 1.625V15.375H4.875V13.1875C4.875 12.3246 5.57455 11.625 6.4375 11.625H9.5625C10.4254 11.625 11.125 12.3246 11.125 13.1875V15.375H13V1.625H3ZM9.875 15.375V13.1875C9.875 13.0149 9.73509 12.875 9.5625 12.875H6.4375C6.26491 12.875 6.125 13.0149 6.125 13.1875V15.375H9.875ZM4.875 4.125C4.875 3.77982 5.15482 3.5 5.5 3.5H6.75C7.09518 3.5 7.375 3.77982 7.375 4.125C7.375 4.47018 7.09518 4.75 6.75 4.75H5.5C5.15482 4.75 4.875 4.47018 4.875 4.125ZM8.625 4.125C8.625 3.77982 8.90482 3.5 9.25 3.5H10.5C10.8452 3.5 11.125 3.77982 11.125 4.125C11.125 4.47018 10.8452 4.75 10.5 4.75H9.25C8.90482 4.75 8.625 4.47018 8.625 4.125ZM4.875 6.625C4.875 6.27982 5.15482 6 5.5 6H6.75C7.09518 6 7.375 6.27982 7.375 6.625C7.375 6.97018 7.09518 7.25 6.75 7.25H5.5C5.15482 7.25 4.875 6.97018 4.875 6.625ZM8.625 6.625C8.625 6.27982 8.90482 6 9.25 6H10.5C10.8452 6 11.125 6.27982 11.125 6.625C11.125 6.97018 10.8452 7.25 10.5 7.25H9.25C8.90482 7.25 8.625 6.97018 8.625 6.625ZM4.875 9.125C4.875 8.77982 5.15482 8.5 5.5 8.5H6.75C7.09518 8.5 7.375 8.77982 7.375 9.125C7.375 9.47018 7.09518 9.75 6.75 9.75H5.5C5.15482 9.75 4.875 9.47018 4.875 9.125ZM8.625 9.125C8.625 8.77982 8.90482 8.5 9.25 8.5H10.5C10.8452 8.5 11.125 8.77982 11.125 9.125C11.125 9.47018 10.8452 9.75 10.5 9.75H9.25C8.90482 9.75 8.625 9.47018 8.625 9.125Z"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="15"
                      height="17"
                      viewBox="0 0 15 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className={clsx(
                        'flex-shrink-0',
                        highlightedServiceIndex === index ? 'fill-primary-pressed' : 'fill-primary group-hover:fill-primary-pressed',
                      )}
                    >
                      <path d="M14.207 13.4445C14.0697 14.3295 13.6208 15.1364 12.941 15.7196C12.2613 16.3027 11.3956 16.6238 10.5 16.625H8.625C7.63076 16.624 6.67753 16.2285 5.97449 15.5255C5.27145 14.8225 4.87603 13.8692 4.875 12.875V9.71016C3.66682 9.55794 2.55572 8.97004 1.75021 8.0568C0.944697 7.14355 0.500153 5.96773 0.5 4.75V1.625C0.5 1.29348 0.631696 0.975537 0.866116 0.741116C1.10054 0.506696 1.41848 0.375 1.75 0.375H3C3.16576 0.375 3.32473 0.440848 3.44194 0.558058C3.55915 0.675268 3.625 0.83424 3.625 1C3.625 1.16576 3.55915 1.32473 3.44194 1.44194C3.32473 1.55915 3.16576 1.625 3 1.625H1.75V4.75C1.74996 5.24669 1.84858 5.73845 2.04016 6.19671C2.23173 6.65497 2.51244 7.0706 2.86597 7.41949C3.2195 7.76837 3.63882 8.04354 4.09958 8.22903C4.56034 8.41452 5.05335 8.50662 5.55 8.5C7.58984 8.47344 9.25 6.76172 9.25 4.68516V1.625H8C7.83424 1.625 7.67527 1.55915 7.55806 1.44194C7.44085 1.32473 7.375 1.16576 7.375 1C7.375 0.83424 7.44085 0.675268 7.55806 0.558058C7.67527 0.440848 7.83424 0.375 8 0.375H9.25C9.58152 0.375 9.89946 0.506696 10.1339 0.741116C10.3683 0.975537 10.5 1.29348 10.5 1.625V4.68516C10.5 7.25078 8.58359 9.39531 6.125 9.70937V12.875C6.125 13.538 6.38839 14.1739 6.85723 14.6428C7.32608 15.1116 7.96196 15.375 8.625 15.375H10.5C11.0677 15.3741 11.6183 15.1803 12.0614 14.8254C12.5046 14.4706 12.814 13.9757 12.9391 13.4219C13.4265 13.4306 14 13.4408 14.207 13.443V13.4445Z" />
                    </svg>
                  )}
                  <div className="flex flex-col gap-2">
                    <span
                      className={clsx(
                        'whitespace-nowrap max-w-[70vw]',
                        highlightedServiceIndex === index ? 'text-primary-pressed' : 'text-primary group-hover:text-primary-pressed',
                      )}
                    >
                      {item.label}
                    </span>
                    <span className="text-xs text-neutral-lighter">
                      {
                        {
                          specialization: 'Specializzazione',
                          service: 'Servizio',
                          ambulatory: 'Ambulatorio',
                        }[item.type]
                      }
                    </span>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div className="flex flex-row md:flex-grow items-center bg-background-neutral rounded-lg md:rounded-l-full relative">
            <Image
              className="absolute md:relative ml-5 mr-2 flex-shrink-0"
              src={locationMarkerIcon}
              alt="icona indicatore di posizione"
              aria-hidden="true"
            />
            <input
              name="location"
              className="bg-background-neutral flex-grow pl-16 md:pl-3 pr-5 rounded-lg md:rounded-none py-3"
              placeholder={component.locationPlaceholder}
              {...getLocationInputProps()}
            />
            <ul
              className={clsx(
                'absolute top-full left-0 bg-white border border-black/5 rounded-lg z-40 min-w-full max-h-64 overflow-y-auto flex flex-col p-3',
                !isLocationsOpen && 'hidden',
              )}
              {...getLocationMenuProps()}
            >
              {locationSerachResults.map((item, index) => (
                <li
                  key={index}
                  className={clsx(
                    'py-3 px-5 flex flex-row gap-1 items-center hover:bg-primary-light-pressed rounded-lg group',
                    index === highlightedLocationIndex && 'bg-primary-light-pressed',
                  )}
                  {...getLocationItemProps({ item, index })}
                >
                  <div className="flex flex-col gap-2">
                    <span
                      className={clsx(
                        'whitespace-nowrap max-w-[70vw]',
                        highlightedLocationIndex === index ? 'text-primary-pressed' : 'text-primary group-hover:text-primary-pressed',
                      )}
                    >
                      {item}
                    </span>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <button
            className="transition-colors py-3 bg-elty-green text-white rounded-lg md:aspect-square md:rounded-full flex-shrink-0 md:px-3.5 md:py-3 hover:bg-brandGreen-600 focus:bg-brandGreen-600"
            type="submit"
            aria-label="Cerca"
          >
            <span className="md:hidden">Cerca</span>
            <Image className="hidden md:block" height={22} width={22} src={searchIcon} alt="icona di ricerca" />
          </button>
        </form>
      </div>
    </div>
  );
}
