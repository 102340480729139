'use client';

import { resolveWebLink } from '@/storyblok/utils/link';
import { LogosStripBigRowStoryblok, StoryblokComponent } from '@/types/types-storyblok';
import { MutableRefObject, useEffect, useRef } from 'react';
import Image from 'next/image';
import { getImageDimensions } from '@/storyblok/utils/asset';
import { StoryblokStory } from 'storyblok-generate-ts';

const scrollSpeed = 0.25;

export const StoryblokLogosStripBigRow = ({
  row,
  index,
  hovering,
  story,
}: {
  row: LogosStripBigRowStoryblok;
  index: number;
  hovering: MutableRefObject<boolean>;
  story: StoryblokStory<StoryblokComponent>;
}) => {
  const direction = index % 2 === 0 ? 'left' : 'right';
  const containerRef = useRef<HTMLUListElement>(null);
  const accumulatedScrollAmount = useRef(0);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;
    const left = Array.from(container.children).map(child => {
      const clone = child.cloneNode(true) as Element;
      container.prepend(clone);
      return clone;
    });
    const right = Array.from(container.children).map(child => {
      const clone = child.cloneNode(true) as Element;
      container.append(clone);
      return clone;
    });

    if (direction === 'left') {
      container.scrollTo({ left: container.scrollWidth, behavior: 'instant' });
    }

    const scroll = () => {
      if (hovering.current) {
        requestAnimationFrame(scroll);
        return;
      }
      accumulatedScrollAmount.current += scrollSpeed;
      if (accumulatedScrollAmount.current >= 0.5) {
        if (direction === 'left') {
          container.scrollLeft -= accumulatedScrollAmount.current;
        } else {
          container.scrollLeft += accumulatedScrollAmount.current;
        }
        accumulatedScrollAmount.current = 0;
      }
      requestAnimationFrame(scroll);
    };
    scroll();

    const elToObserve = container.children.item(direction === 'left' ? 0 : container.children.length - 1);
    if (!elToObserve) throw new Error('Element to observe not found');
    let observedElement = elToObserve;
    const xGapBetweenElements = Math.abs(
      container.children.item(0)!.getBoundingClientRect().right - container.children.item(1)!.getBoundingClientRect().left,
    );
    const observer = new IntersectionObserver(entries => {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          const elementsToMove = [];
          let amountToScroll = 0;
          for (let i = 0; i < row.logos.length; i++) {
            const el = container.children.item(direction === 'left' ? row.logos.length - 1 - i : i)!;
            amountToScroll += el.getBoundingClientRect().width;
            amountToScroll += xGapBetweenElements;
            elementsToMove.push(el);
          }

          if (direction === 'left') {
            container.prepend(...elementsToMove);
            container.scrollTo({ left: container.scrollLeft + amountToScroll, behavior: 'instant' });
          } else {
            container.append(...elementsToMove);
            container.scrollTo({ left: container.scrollLeft - amountToScroll, behavior: 'instant' });
          }

          observer.unobserve(entry.target);
          observer.observe(container.children.item(direction === 'left' ? 0 : container.children.length - 1)!);
        }
      }
    });
    observer.observe(observedElement);

    return () => {
      observer.disconnect();

      left.forEach(el => (el as Element).remove());
      right.forEach(el => (el as Element).remove());
    };
  }, [direction, row.logos.length, hovering]);
  return (
    <ul
      className="flex flex-row gap-x-3 w-full overflow-x-auto scrollbar-hide"
      ref={containerRef}
      onMouseEnter={() => (hovering.current = true)}
      onMouseLeave={() => (hovering.current = false)}
    >
      {row.logos.map(logo => (
        <li className="flex-shrink-0 w-64 bg-[#224869] border-solid border border-white/10 h-20 rounded-xl" key={logo._uid}>
          {logo.link ? (
            <a className="w-full h-full flex justify-center items-center" href={resolveWebLink(logo.link, { story })}>
              <Image src={logo.image.filename} alt={logo.image.alt ?? ''} {...getImageDimensions(logo.image)} />
            </a>
          ) : (
            <div className="w-full h-full flex justify-center items-center">
              <Image src={logo.image.filename} alt={logo.image.alt ?? ''} {...getImageDimensions(logo.image)} />
            </div>
          )}
        </li>
      ))}
    </ul>
  );
};
