import { HomeHeroBannerSelectStoryblok } from '@/types/types-storyblok';
import { StoryblokReactComponentProps } from '../componentsMap';
import { storyblokEditable } from '@storyblok/react';
import clsx from 'clsx';

export function StoryblokHomerHeroBannerSelect({ component }: StoryblokReactComponentProps<HomeHeroBannerSelectStoryblok>) {
  const services = extractOptions(component.serviceSelectOptions);
  const cities = extractOptions(component.citySelectOptions);

  return (
    <div
      className="mx-4 md:mx-14 lg:ml-20 xl:mx-auto xl:max-w-screen-xl xl:w-full xl:flex xl:flex-row relative z-[3] "
      {...storyblokEditable(component)}
    >
      <div className="bg-white p-6 flex flex-col gap-6 relative border border-black/5 rounded-lg ">
        <form
          className={clsx(
            'flex flex-col gap-3 md:grid md:p-1',
            component?.enableCitySelect ? 'md:grid-cols-[1fr,1fr,min-content]' : 'md:grid-cols-[1fr,min-content]',
          )}
          action="/cerca"
        >
          <div className="flex-1 flex flex-col gap-1">
            {component.serviceSelectLabel && (
              <label className="text-sm font-medium" htmlFor="service">
                {component.serviceSelectLabel}
              </label>
            )}
            <select id="service" name="service" className="bg-background-neutral  px-3 rounded-lg py-3 w-full lg:w-86 xl:w-96">
              {services.map(service => (
                <option value={service} key={service}>
                  {service}
                </option>
              ))}
            </select>
          </div>
          {!!component.enableCitySelect && (
            <div className="flex-1 flex flex-col gap-1 justify-end">
              {component.citySelectLabel && (
                <label className="text-sm font-medium" htmlFor="service">
                  {component.citySelectLabel}
                </label>
              )}
              <select name="location" className="bg-background-neutral px-3 rounded-lg py-3 w-full lg:w-86 xl:w-96">
                {cities.map(city => (
                  <option value={city} key={city}>
                    {city}
                  </option>
                ))}
              </select>
            </div>
          )}
          {component.cityDefaultValue && <input type="hidden" name="location" value={component.cityDefaultValue} />}
          <div className="flex flex-col justify-end">
            <button
              className="transition-colors whitespace-nowrap py-3 bg-elty-blue text-white font-semibold rounded flex-shrink-0 md:px-3.5 hover:bg-brandBlue-600 focus:bg-brandBlue-600"
              type="submit"
              aria-label={component.callToActionLabel}
            >
              <span>{component.callToActionLabel}</span>
            </button>
          </div>
          {component.bottomLabel && <p className="md:col-span-3 text-text-body text-sm">{component.bottomLabel}</p>}
        </form>
      </div>
    </div>
  );
}

function extractOptions(options: string | undefined) {
  return Array.from(
    new Set(
      (options ?? '')
        .split('\n')
        .flatMap(str => str.split(','))
        .map(str => str.trim())
        .filter(str => str.length > 0),
    ).values(),
  );
}
