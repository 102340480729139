'use client';

import { PriceCardStoryblok } from '@/types/types-storyblok';
import { StoryblokReactComponent } from '../componentsMap';
import clsx from 'clsx';
import { storyblokEditable } from '@storyblok/js';
import Link from 'next/link';
import { resolveWebLink } from '@/storyblok/utils/link';
import { blueCheck, greenCheck, roseCheck } from './checks';

export const StoryblokPriceCard: StoryblokReactComponent<PriceCardStoryblok> = ({ component: card, story }) => {
  const cta = card.cta?.at(0);

  let bgHighlightClass = '';
  if (card.highlight) {
    if (card.color === 'green' || card.color === '') {
      bgHighlightClass = 'bg-[linear-gradient(90deg,_#0BBCA7_0%,_#5182EF_100%)]';
    } else if (card.color === 'blue') {
      bgHighlightClass = 'bg-[linear-gradient(90deg,_#5182EF_0%,_#759AEE_100%)]';
    } else if (card.color === 'rose') {
      bgHighlightClass = 'bg-[linear-gradient(90deg,_#E25292_0%,_#759AEE_100%)]';
    }
  } else {
    bgHighlightClass = 'bg-background-neutral';
  }

  return (
    <div className="flex-grow shrink-0 w-full lg:max-w-[33%]">
      <div
        {...storyblokEditable(card)}
        className={clsx(
          'flex shadow w-full h-full rounded-xl relative  transition-transform hover:-translate-y-1',
          card.highlight ? 'p-[3px]' : 'p-[1.5px]',
          bgHighlightClass,
        )}
      >
        {card.highlight && (
          <div
            className={clsx(
              'rounded-full px-5 py-2 text-white absolute top-0 -translate-y-1/2 left-1/2 -translate-x-1/2',
              bgHighlightClass,
            )}
          >
            {card.highlight}
          </div>
        )}
        <div className="bg-white flex flex-col pt-10 pb-4 px-6 h-full w-full rounded-[10px]">
          <h3
            className={clsx(
              'text-2xl font-semibold mb-4',
              (card.color === '' || card.color === 'green') && 'text-brandGreen-400',
              card.color === 'blue' && 'text-brandBlue-500',
              card.color === 'rose' && 'text-pink-400',
            )}
          >
            {card.name}
          </h3>
          <div className="flex flex-row gap-1 items-center">
            {card.discountedValue && (
              <div className="relative">
                <div
                  aria-hidden
                  className="pointer-events-none bg-neutral-500 absolute -rotate-12 h-[1.5px] w-full left-0 bottom-2.5 "
                ></div>
                <span className="text-neutral-lighter text-sm font-medium">{card.discountedValue}</span>
              </div>
            )}
            <span className="text-4xl font-semibold">{card.value}</span>
            <div className="flex-grow h-full flex flex-col justify-end">
              <span className="font-medium">{card.valueSuffix}</span>
            </div>
          </div>
          {card.description && <span>{card.description}</span>}
          <div className="h-8" />
          {card.content && (
            <>
              <p className="text-neutral-lighter">{card.content}</p>
              <div className="h-8" />
            </>
          )}

          {cta && (
            <Link
              className="transition-colors bg-primary text-white text-lg font-medium text-center w-full py-4 rounded-lg hover:bg-primary-hover"
              href={resolveWebLink(cta.link, { story })}
            >
              {cta.label}
            </Link>
          )}

          {card.bulletPoints && (
            <ul className="flex flex-col gap-5 py-8">
              {card.bulletPoints.map(point => (
                <li key={point._uid} {...storyblokEditable(point)} className="flex flex-row items-center justify-start gap-5">
                  <div className="shrink-0">
                    {(card.color === 'green' || card.color === '') && greenCheck}
                    {card.color === 'blue' && blueCheck}
                    {card.color === 'rose' && roseCheck}
                  </div>
                  <span>{point.content}</span>
                </li>
              ))}
              {card.bulletPointsEndText && <li className="text-neutral-lighter">{card.bulletPointsEndText}</li>}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};
