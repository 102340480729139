import { StoryblokReactComponent } from '../componentsMap';
import { LogosStripBigStoryblok } from '@/types/types-storyblok';
import { StoryblokLogosStripBigRows } from './rows';

export const StoryblokLogosStripBig: StoryblokReactComponent<LogosStripBigStoryblok> = ({ component, story }) => {
  return (
    <section className="bg-elty-blue text-white px-4 md:px-20 xl:px-0">
      <div className="max-w-section xl:mx-auto flex flex-col py-20 gap-20">
        <div className="flex flex-col gap-6">
          <h2 className="text-center text-5xl">{component.title}</h2>
          {component.subtitle && <p className="text-xl text-neutral-200 text-center inline-block w-full">{component.subtitle}</p>}
        </div>
        <div className="flex flex-col gap-y-3 relative overflow-x-auto">
          <div className="absolute pointer-events-none left-0 h-full w-1/4 max-w-lg bg-[linear-gradient(90deg,_#0F3250_15%,_rgba(15,_50,_80,_0)_100%)]"></div>
          <StoryblokLogosStripBigRows component={component} story={story} />
          <div className="absolute pointer-events-none right-0 h-full rotate-180 w-1/4 max-w-lg bg-[linear-gradient(90deg,_#0F3250_15%,_rgba(15,_50,_80,_0)_100%);]"></div>
        </div>
      </div>
    </section>
  );
};
