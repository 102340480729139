export const greenCheck = (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.0604 7.93918C25.3416 8.22047 25.4996 8.60193 25.4996 8.99968C25.4996 9.39743 25.3416 9.77889 25.0604 10.0602L13.0604 22.0602C12.7791 22.3414 12.3976 22.4994 11.9999 22.4994C11.6021 22.4994 11.2207 22.3414 10.9394 22.0602L4.93938 16.0602C4.66614 15.7773 4.51495 15.3984 4.51837 15.0051C4.52178 14.6118 4.67954 14.2356 4.95765 13.9575C5.23576 13.6793 5.61198 13.5216 6.00528 13.5182C6.39857 13.5148 6.77747 13.6659 7.06038 13.9392L11.9999 18.8787L22.9394 7.93918C23.2207 7.65797 23.6021 7.5 23.9999 7.5C24.3976 7.5 24.7791 7.65797 25.0604 7.93918Z"
      fill="#3DA9A0"
    />
  </svg>
);

export const blueCheck = (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="16" viewBox="0 0 22 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.0604 0.93918C21.3416 1.22047 21.4996 1.60193 21.4996 1.99968C21.4996 2.39743 21.3416 2.77889 21.0604 3.06018L9.06038 15.0602C8.77909 15.3414 8.39762 15.4994 7.99988 15.4994C7.60213 15.4994 7.22067 15.3414 6.93938 15.0602L0.939378 9.06018C0.666141 8.77728 0.51495 8.39837 0.518367 8.00508C0.521785 7.61178 0.679538 7.23556 0.95765 6.95745C1.23576 6.67934 1.61198 6.52159 2.00528 6.51817C2.39857 6.51475 2.77747 6.66594 3.06038 6.93918L7.99988 11.8787L18.9394 0.93918C19.2207 0.657973 19.6021 0.5 19.9999 0.5C20.3976 0.5 20.7791 0.657973 21.0604 0.93918Z"
      fill="#2563EB"
    />
  </svg>
);

export const roseCheck = (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.0604 7.93918C25.3416 8.22047 25.4996 8.60193 25.4996 8.99968C25.4996 9.39743 25.3416 9.77889 25.0604 10.0602L13.0604 22.0602C12.7791 22.3414 12.3976 22.4994 11.9999 22.4994C11.6021 22.4994 11.2207 22.3414 10.9394 22.0602L4.93938 16.0602C4.66614 15.7773 4.51495 15.3984 4.51837 15.0051C4.52178 14.6118 4.67954 14.2356 4.95765 13.9575C5.23576 13.6793 5.61198 13.5216 6.00528 13.5182C6.39857 13.5148 6.77747 13.6659 7.06038 13.9392L11.9999 18.8787L22.9394 7.93918C23.2207 7.65797 23.6021 7.5 23.9999 7.5C24.3976 7.5 24.7791 7.65797 25.0604 7.93918Z"
      fill="#E25292"
    />
  </svg>
);
