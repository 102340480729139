'use client';

import { useRef } from 'react';
import { LogosStripBigStoryblok, StoryblokComponent } from '@/types/types-storyblok';
import { StoryblokLogosStripBigRow } from './row';
import { StoryblokStory } from 'storyblok-generate-ts';
export const StoryblokLogosStripBigRows = ({
  component,
  story,
}: {
  component: LogosStripBigStoryblok;
  story: StoryblokStory<StoryblokComponent>;
}) => {
  const hovering = useRef(false);

  return (
    <>
      {component.rows.map((row, index) => (
        <StoryblokLogosStripBigRow key={row._uid} row={row} index={index} hovering={hovering} story={story} />
      ))}
    </>
  );
};
