import { storyblokEditable } from '@storyblok/js';
import { StoryblokReactComponent } from './componentsMap';
import { ImageStoryblok } from '@/types/types-storyblok';
import Image from 'next/image';
import { getImageDimensions } from '../utils/asset';
import clsx from 'clsx';
import Link from 'next/link';
import { resolveWebLink } from '../utils/link';

export const StoryblokImage: StoryblokReactComponent<ImageStoryblok> = ({ component, story }) => {
  const isInsidePost = story.content.component === 'post';
  const image = (
    <Image
      className="w-full h-auto"
      src={component.image.filename}
      alt={component.image.alt ?? ''}
      {...getImageDimensions(component.image)}
    />
  );

  const containerClasses = clsx('max-w-section xl:mx-auto', isInsidePost && 'px-4');

  if (component.link) {
    return (
      <Link {...storyblokEditable(component)} className={containerClasses} href={resolveWebLink(component.link, { story })}>
        {image}
      </Link>
    );
  }

  return (
    <div {...storyblokEditable(component)} className={containerClasses}>
      {image}
    </div>
  );
};
