'use client';

import { PricesCompareTableStoryblok, PricesStoryblok } from '@/types/types-storyblok';
import { StoryblokReactComponentProps } from '../componentsMap';
import { PropsWithChildren, useMemo } from 'react';
import { blueCheck, greenCheck, roseCheck } from './checks';

type PlanColor = 'green' | 'blue' | 'rose';

const ALL = Symbol.for('ALL');

export const StoryblokPricesCompareTable = ({
  component,
  prices,
}: StoryblokReactComponentProps<PricesCompareTableStoryblok> & { prices: PricesStoryblok }) => {
  const idxToColor: Record<number, PlanColor> = useMemo(() => {
    const idxToColor: Record<number, PlanColor> = {};
    const head = component.table?.thead;
    if (!head) return {};
    for (let i = 0; i < head.length - 1; i++) {
      const planName = head.at(i + 1)?.value;
      if (!planName) continue;
      const color = prices.tabs?.at(0)?.cards?.find(card => (card.name ?? '').toLowerCase() === planName.toLowerCase())?.color;
      if (!color) {
        idxToColor[i] = 'green';
      } else {
        idxToColor[i] = color as PlanColor;
      }
    }
    return idxToColor;
  }, [component, prices]);

  const featuresToPlans: Record<string, typeof ALL | Array<{ label: string; color: PlanColor }>> = useMemo(() => {
    const features: { featureName: string; index: number }[] =
      component.table?.tbody
        .map(row => row.body.at(0))
        .filter(Boolean)
        .map((cell, i) => ({ featureName: cell!.value ?? '', index: i })) ?? [];

    const numberOfPlans = (component.table?.thead.length ?? 0) - 1;

    if (numberOfPlans <= 0) return {};

    return features.reduce(
      (acc, { featureName, index }) => {
        const plansHavingFeature = (component.table?.tbody
          .at(index)
          ?.body.filter((_, i) => i !== 0)
          .map((cell, i) => (!!cell.value ? { label: component.table?.thead.at(i + 1)?.value ?? '', color: idxToColor[i] } : undefined))
          .filter(Boolean) ?? []) as Array<{ label: string; color: PlanColor }>;

        if (plansHavingFeature.length === 0) return acc;
        else if (plansHavingFeature.length === numberOfPlans) return { ...acc, [featureName]: ALL };
        else return { ...acc, [featureName]: plansHavingFeature };
      },
      {} as Record<string, typeof ALL | Array<{ label: string; color: PlanColor }>>,
    );
  }, [component, idxToColor]);

  return (
    <div className="mt-32">
      <h3 className="text-center text-neutral-darker text-5xl font-medium">{component.title}</h3>
      {component.content && (
        <p className="text-xl text-neutral-lighter font-normal mt-10 mb-24 lg:w-3/4 text-center mx-auto">{component.content}</p>
      )}

      {component.table && (
        <>
          <div className="hidden lg:block">
            <table className="w-full">
              <thead>
                <tr>
                  {component.table.thead.map((head, i) =>
                    i === 0 ? (
                      <td key={head._uid} className="text-neutral-darker text-3xl font-semibold">
                        {head.value}
                      </td>
                    ) : (
                      <td key={head._uid} className="text-2xl font-medium text-center">
                        <ColoredText color={idxToColor[i - 1]}>{head.value}</ColoredText>
                      </td>
                    ),
                  )}
                </tr>
              </thead>
              <tbody>
                {component.table.tbody.map(row => (
                  <tr key={row._uid}>
                    {row.body.map((cell, i) =>
                      i === 0 ? (
                        <td key={cell._uid} className="text-lg border-b h-[1px] pt-14 pb-5">
                          <div className="flex flex-col justify-center h-full">{cell.value}</div>
                        </td>
                      ) : (
                        <td key={cell._uid} className="px-14 pb-5 pt-14 border-b">
                          <div className="flex flex-row justify-center">{!!cell.value ? <Check color={idxToColor[i - 1]} /> : ''}</div>
                        </td>
                      ),
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="flex lg:hidden">
            <ul className="flex flex-col gap-5 w-full">
              {Object.entries(featuresToPlans).map(([feature, plans]) => (
                <li key={feature} className="flex flex-row justify-between pb-7 border-b">
                  <span>{feature}</span>
                  <div className="flex flex-row gap-2">
                    {plans === ALL ? (
                      <MobilePlanBadge color="gray" label="Tutti i piani" />
                    ) : (
                      plans.map(plan => <MobilePlanBadge key={plan.label} color={plan.color} label={plan.label} />)
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
    </div>
  );
};

const MobilePlanBadge = ({ color, label }: { color: PlanColor | 'gray'; label: string }) => {
  switch (color) {
    case 'green':
      return <span className="text-brandGreen-400 bg-brandGreen-100 p-2 rounded">{label}</span>;
    case 'blue':
      return <span className="text-brandBlue-500 bg-brandBlue-100 p-2 rounded">{label}</span>;
    case 'rose':
      return <span className="text-pink-500 bg-pink-100 p-2 rounded">{label}</span>;
    case 'gray':
      return <span className="bg-neutral-100 text-neutral-darker p-2 rounded">{label}</span>;
    default:
      return null;
  }
};

const Check = ({ color }: { color: PlanColor }) => {
  switch (color) {
    case 'green':
      return greenCheck;
    case 'blue':
      return blueCheck;
    case 'rose':
      return roseCheck;
    default:
      return null;
  }
};

const ColoredText = ({ color, children }: PropsWithChildren<{ color: PlanColor }>) => {
  switch (color) {
    case 'green':
      return <span className="text-brandGreen-400">{children}</span>;
    case 'blue':
      return <span className="text-brandBlue-500">{children}</span>;
    case 'rose':
      return <span className="text-rose-500">{children}</span>;
    default:
      return null;
  }
};
